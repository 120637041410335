.mdc-card {
  &.info-card {
    position: relative;
    padding: 17px 25px;
    .card-inner {
      margin-right: 25%;
      .card-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 9px;
      }
      .card-icon-wrapper {
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 57px;
        height: 57px;
        border-radius: 2px;
        background-color: theme-color(success);
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        box-shadow: 0 0 10px 5px rgba(theme-color(success), 0.35);
        @media (max-width: 992px) {
          right: 20px;
        }
        i {
          color: #fff;
          font-size: 30px;
        }
      }
    }
    @each $color, $value in $theme-colors {
      &.info-card--#{$color} {
        .card-inner {
          .card-icon-wrapper {
            background-color: $value;
            box-shadow: 0 0 10px 5px rgba($value, 0.35);
          }
        }
      }
    }
  }
  .options-icon,
  .refresh-icon {
    font-size: 20px;
    color: #bbbbbb;
    cursor: pointer;
  }
}

.statitics-card {
  border-right: 1px solid $border-color;
  @media (max-width: 767px) {
    border-right: none;
  }
  @media (max-width: 991px) {
    border-bottom: 1px solid $border-color;
  }
  .statitics-card-header {
    i {
      font-size: 18px;
      color: #a9a7a7;
    }
  }
  .statitics-card-content {
    i {
      font-size: 40px;
    }
  }
}

.revenue-tab {
  .mdc-tab-bar {
    .mdc-tab-indicator {
      .mdc-tab-indicator__content--underline {
        border: none;
      }
    }
  }
  &.mdc-tab-wrapper {
    .content {
      padding: 0;
    }
  }
}

.bullet-rounded {
  content: "";
  width: 6px;
  height: 6px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
}

.product-card {
  .product-box {
    position: relative;
    padding: 0px 31px 25px 31px;
    display: flex;
    justify-content: center;
    .product-name-box {
      position: absolute;
      width: 83%;
      color: #ffffff;
      transform: translateY(-50%);
      @each $color, $value in $theme-colors {
        .product-name-box-#{$color} {
          height: 75px;
          box-shadow: 0 0 10px 5px rgba($value, 0.35);
          background: $value;
          transition: all 0.25s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.03);
          }
        }
      }
      .add-product-button {
        position: absolute;
        right: -17px;
        top: 25%;
      }
    }
    .product-description-box {
      margin-top: 60px;
      .product-description-box-text {
        color: #3c4858;
      }
    }
  }
}

.earning-flot-chart {
  height: 237px;
}

.revenue-world-map {
  width: 100%;
  height: 200px;
}

.flag-icon-container {
  display: inline-block;
  .flag-icon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-size: cover;
  }
}

.dashboard-table,
.mdc-data-table__table {
  // &.table {
  //   tbody,
  //   thead {
  //     tr {
  //       td,
  //       th {
  //         text-align: left;
  //         &:first-child {
  //           padding-left: 0;
  //         }
  //         &:last-child {
  //           padding-right: 0;
  //         }
  //       }
  //     }
  //   }
  // }
  &.sales-table {
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    &.table {
      thead,
      tbody {
        tr {
          th,
          td {
            color: inherit;
            padding: 10px 10px;
            font-weight: normal;
          }
          &:hover {
            background: #f8f6ff;
          }
        }
      }
    }
  }
}

.product-list {
  .product-list-header {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
  }
  .product-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $border-color;
    padding: 1rem 1.5rem;
    .options-icon {
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.dashboard-inline-datepicker {
  &.mdc-card {
    background: #f9f9fa;
    position: relative;
    min-height: 400px;
  }
  .datepicker-box-top {
    display: flex;
    justify-content: space-between;
    background: theme-color(info);
    height: 70px;
    padding: 1.5rem;
    .mdc-button {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    }
  }
}

.to-do-list {
  .to-do-list-header {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    i {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .mdc-tab-bar {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.17);
  }
  .mdc-tab-indicator {
    .mdc-tab-indicator__content--underline {
      border-color: #ffffff;
    }
  }
  .to-do-list-content {
    padding: 10px 30px;
    background: #ffffff;
    .to-do-list-item {
      border: 1px solid #eeeeee;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      &:before {
        content: "";
        width: 4px;
        height: 60px;
        background: #ffcd00;
      }
    }
    .delete-icon {
      color: #bababa;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.box-placeholder {
  display: inline-block;
  // background-color: #fff;
  // margin: 30px;
  // box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1);
  // max-width: 300px;
  vertical-align: top;
  width: 100%;

  // hr {
  //   margin: 0;
  //   border-color: #f3f3f3;
  // }
}

.box-placeholder {
  // font-size: 0;
  // line-height: 0;

  .text {
    display: inline-block;
    background-color: #444;
    height: 10px;
    border-radius: 2px;
    margin: 5px 0;
    // min-width: 100px;
    opacity: 0.1;
    animation: fading 1.5s infinite;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.link {
      background-color: var(--blue);
      opacity: 0.4;
    }

    &.line {
      width: 100%;
    }

    &.category {
      width: 100px;
      margin-bottom: 10px;
    }
  }

  .image {
    background-color: #f9f9f9;
  }
}

@keyframes fading {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.1;
  }
}

.search-filters a {
  color: var(--mdc-theme-primary) !important;
}

.mdc-data-table {
  border-radius: 0px;
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding-right: 1px;
  padding-left: 1px;
}

.mdc-data-table__cell {
  height: inherit;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
}

table.gridview tbody,
table.gridview,
table.mdc-data-table__table tbody,
table.mdc-data-table__table {
  width: 100%;
  min-width: 990px;
  tr {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgba(0, 0, 0, 0.12);
    background-color: inherit;
    height: 26px !important;
    td {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      letter-spacing: 0.01786em;
      text-decoration: inherit;
      text-transform: inherit;
      box-sizing: border-box;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 26px !important;
    }
    th,
    td {
      text-align: left;
      padding: 1px 5px;
      &:first-child {
        padding-left: 2px;
      }
      &:last-child {
        padding-right: 2px;
      }
    }
  }

  tr:not(.row--selected):hover {
    background-color: #f5f5f5;
  }
}
.modal-popup .mdc-data-table__table {
  min-width: 400px !important;
}

.modal-background {
  background-color: gray;
  filter: alpha(opacity=70);
  opacity: 0.7;
}

.modal-popup {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: Gray;
  padding: 3px;
  width: 800px;
  overflow-y: auto;
  height: auto;
  max-height: 800px;

  .modal-popup--content-two-col {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    // align-content: space-between;
    width: 799px;
    > div {
      padding: 0.375rem 0;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      &:not(.w-100) {
        @media (max-width: 767px) {
          //small screens
          width: 100%;
        }
        @media (min-width: 768px) {
          width: calc(50% - 20px);
          margin-right: 16px;
        }
      }
      &.w-100 {
        width: 100% !important;
        textarea {
          width: calc(100% - 80px - 40px) !important;
          margin-left: 16px;
        }
        input[type="text"] {
          width: 30px !important;
          margin-bottom: auto;
        }
      }
      // margin-right: 16px;

      > label,
      > span {
        width: 120px;
        font-weight: 600;
      }
      input[type="text"],
      select {
        width: calc(380px - 120px - 16px - 16px) !important; //w-label-el-margin
        margin-left: 16px;
      }
    }
    &.space-between > div {
      justify-content: space-between !important;
    }
    // > div:not(:last-child) {
    //   border-bottom: 1px solid $gray;
    // }
    &.border-bottom {
      > div:not(.border-none) {
        border-bottom: 1px solid $gray;
      }
    }
  }
}
.modal-popup--drag {
  cursor: move;
}
.modal-popup--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
  align-items: center;
  border-bottom: 1px solid $gray;
}

.modal-popup--close {
  // height: 32px;
  // width: 32px;
  cursor: pointer;
  color: #000;
  font-size: 2rem;
  padding: 0.125rem;
}
// .mdc-button--raised:not(:disabled), .mdc-button--unelevated:not(:disabled) {
//   color: #fff;
//   color: var(--mdc-theme-on-primary, #fff);
// }

// .mdc-button--raised:not(:disabled), .mdc-button--unelevated:not(:disabled) {
//   background-color: var(--mdc-theme-primary, #009BD8);
// }
// .mdc-button--raised, .mdc-button--unelevated {
//   padding: 0 16px 0 16px;
// }

// .content-wrapper {
//   ////////// Button //////////
//   input[type="sumbit"], .btn {
//     text-transform: none;
//     white-space: nowrap;
//     border-radius: 6px;
//     height: 26px;
//     -moz-osx-font-smoothing: grayscale;
//     -webkit-font-smoothing: antialiased;
//     // line-height: 2.25rem;
//     font-weight: 500;
//     font-size: 0.875rem;
//     letter-spacing: 0.08929em;
//     text-decoration: none;
//     text-transform: none;
//     padding: 0 16px 0 16px;
//     display: inline-flex;
//     position: relative;
//     align-items: center;
//     justify-content: center;
//     box-sizing: border-box;
//     min-width: 100px;
//     border: none;
//     outline: none;
//     line-height: inherit;
//     user-select: none;
//     -webkit-appearance: none;
//     overflow: visible;
//     vertical-align: middle;
//     cursor: pointer;
//   }
//   .btn:not(:disabled), input[type="sumbit"]:not(:disabled) {
//     background-color: $mdc-theme-primary;
//     color: $white;
//   }

//   .btn--outlined {
//     border: 1px solid $mdc-theme-primary;
//     // line-height: 2.25rem;
//     font-weight: 500;
//     letter-spacing: 0.08929em;
//     font-size: 0.875rem;
//     text-decoration: none;
//     text-transform: uppercase;
//     padding: 0 15px 0 15px;
//     border-radius: 6px;
//     height: 26px;
//     -moz-osx-font-smoothing: grayscale;
//     -webkit-font-smoothing: antialiased;
//     // display: inline-flex;
//     // align-items: center;
//     justify-content: center;
//     min-width: 80px;
//     cursor: pointer;
//   }
//   .btn--outlined:not(:disabled) {
//     border-color: #009BD8;
//     background-color: $mdc-theme-primary;
//   }
//   .btn-lg {
//     height: 34px;
//   }

//   ////////// Fieldset //////////

//   fieldset {
//     border: 1px solid $gray-light;
//     border-radius: 6px;
//     legend {
//       font-size: 0.875rem;
//     }
//   }

// }
.report-filters-bg {
  background-color: $blue-lily-white;
}
.search-filters-box {
  background-color: $blue-lily-white;
  margin-bottom: 1rem !important;
  padding: 0.5rem !important;
}
.search-filters-toggle {
  @include display-flex();
  @include flex-direction(row);
  align-items: center;
  justify-content: end;
  font-weight: 600;
  font-size: 0.69rem;
  color: $mdc-theme-primary;

  > label {
    cursor: pointer;
  }
  > i {
    cursor: pointer;
  }
}
.collapsible {
  font-weight: 600;
  font-size: 0.69rem;
  color: $mdc-theme-primary;
  > label {
    cursor: pointer;
  }
  > i {
    cursor: pointer;
  }
}
.help-text {
  background: #fff6de;
  font-size: 0.69rem;
}
// .search-filters {
//   // d-flex flex-row bg-search-form mb-3 p-2
//   @include display-flex();
//   @include flex-direction(row);
//   background: $blue-lily-white;
//   // margin-bottom: 1rem !important;
//   // padding: 0.5rem !important;
//   // border-radius: 6px;
//   .d-flex {
//     // flex-direction: row;
//     flex-flow: row wrap;
//     justify-content: flex-start;
//     align-items: stretch;
//     > div {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       width: 256px;
//       margin-right: 5px;
//       input[type="text"],
//       select {
//         width: 180px;
//       }

//       > div {
//         display: inline-flex;
//         padding: 4px;
//         justify-content: space-between;
//         align-items: center;
//       }
//     }
//   }
// }

input[type="text"],
input[type="password"],
input {
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid $input-border-color;
  box-sizing: border-box;
  height: 26px;
  padding: 6px 6px 6px;
}

textarea {
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid $input-border-color;
  box-sizing: border-box;
  padding: 6px 6px 6px;
}

select {
  border: 1px solid $input-border-color;
  background: #ffffff;
  border-radius: 4px;
  height: 26px;
  padding: 2px;
}

input[type="submit"] {
  padding: 0 16px 0 16px;
  text-transform: none;
  white-space: nowrap;
}

// input[type="submit"] {
//   -moz-osx-font-smoothing: grayscale;
//   -webkit-font-smoothing: antialiased;
//   font-size: 0.875rem;
//   /* line-height: 2.25rem; */
//   font-weight: 500;
//   letter-spacing: 0.08929em;
//   text-decoration: none;
//   /* text-transform: uppercase; */
//   padding: 0 16px 0 16px;
//   display: inline-flex;
//   position: relative;
//   align-items: center;
//   justify-content: center;
//   box-sizing: border-box;
//   min-width: 80px;
//   border: none;
//   outline: none;
//   line-height: inherit;
//   user-select: none;
//   -webkit-appearance: none;
//   overflow: visible;
//   vertical-align: middle;
//   border-radius: 4px;
//   height: 26px;
//   cursor: pointer;
// }

// input[type="submit"]:not(:disabled) {
//   color: #fff;
//   color: var(--mdc-theme-on-primary, #fff);
//   background-color: var(--mdc-theme-primary, #009BD8);
// }

fieldset {
  border: 1px solid $gray-light;
  // border-radius: 6px;
  legend {
    font-size: 0.875rem;
  }
}

.d-flex-row-wrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start; //align the div el at the top
  > label,
  > select,
  > input {
    margin: 0 3px;
  }

  > div {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
    margin-right: 20px;
    padding: 2px;
    align-items: center;
    input[type="text"],
    select {
      width: 240px;
    }
    width: 320px;
    label,
    select,
    input {
      margin: 0 3px;
    }

    > div {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      //margin-right: 5px;
      align-items: center;
      input[type="text"],
      select {
        width: 240px;
      }
      width: 320px;
    }
  }
  select.sort-field {
    width: 200px;
  }
  select.sort-option {
    width: 60px;
  }
  select.year-field {
    width: 100px;
  }
}
.d-flex-cols-1 {
  max-width: 325px;
}
.d-flex-cols-2 {
  max-width: 700px;
}
.d-flex-cols-3 {
  max-width: 1040px;
}
.d-flex-cols-4 {
  max-width: 1380px;
}
.d-flex-cols-5 {
  max-width: 1280px;
}

.d-flex--multi-cols {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start; //align the div el at the top
  // > label,
  // > select,
  // > input {
  //   margin: 0px;
  // }
  // flex children
  > div {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
    padding: 2px;
    input[type="text"],
    select {
      width: 240px;
    }
    input[type="date"] {
      width: 128px;
    }
    width: 360px;
    label,
    select, span,
    input {
      margin: 0px;
    }
    > label:first-child, > span:first-child {
      width: 100px;
      font-weight: 600;
    }
    > div {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-weight: 400;
    }
  }
  > div.width-sm {
    width: 160px !important;
  }
  > div.width-md {
    width: 200px !important;
  }
  select.width-md,
  input[type="text"].width-md {
    width: 160px;
  }
  select.width-sm {
    width: 100px;
  }
  select.width-xs {
    width: 80px;
  }
}

.d-flex-row-wrap--space-between {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center; //align the div el at the top
  padding: 0 5px;
  > div {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
  }
}

.search-filters, .d-flex--multi-cols {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center; //align the div el at the top
  > label,
  > select,
  > input {
    margin: 0px;
  }
  // flex children
  > div {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 10px;
    padding: 2px;
    align-items: center;
    input[type="text"],
    select {
      width: 240px;
    }
    input[type="date"] {
      width: 128px;
    }
    width: 360px;
    label,
    select,
    input {
      margin: 0px;
    }
    > label {
      width: 80px;
      font-weight: 400;
    }
    > div {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }
  > div.width-sm {
    width: 160px !important;
  }
  > div.width-md {
    width: 200px !important;
  }
  select.width-md,
  input[type="text"].width-md {
    width: 160px;
  }
  select.width-sm {
    width: 100px;
  }
  select.width-xs {
    width: 80px;
  }
}

.report-filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center; //align the div el at the top
  > label,
  > select,
  > input {
    margin: 0px;
  }
  // flex children
  > div {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    padding: 2px;
    align-items: flex-start;
    input[type="text"],
    select {
      width: 240px;
    }
    input[type="date"] {
      width: 128px;
    }
    width: 250px;
    label,
    select,
    input {
      margin: 0px;
    }
  }
  > div.width-sm {
    width: 160px !important;
  }
  > div.width-md {
    width: 200px !important;
  }
  select.width-sm {
    width: 100px;
  }
}
// .d-flex-col-wrap {
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   > div {
//     display: flex;
//     flex-flow: row wrap;
//     justify-content: flex-start;
//     margin-right: 5px;
//     align-items: center;
//     > div {
//       display: inline-flex;
//       padding: 4px;
//       justify-content: space-between;
//       align-items: center;
//       input[type="text"], select{
//         width: 180px;
//       }
//       width: 250px;
//     }
//   }
// }

// .login-section {
//   input[type="text"] {
//     height: 42px;
//   }
//   .title--black {
//     color: #000;
//     font-weight: bold;
//     font-size: 32px;
//   }
//   title--blue {
//     color: var(--mdc-theme-primary);
//     font-weight: bold;
//     font-size: 32px;
//   }
//   input[type="checkbox"] {
//     background: var(--mdc-theme-primary);
//     color: var(--mdc-theme-primary);
//   }
//   label {
//     font-size: 0.75rem;
//     font-weight: 600;
//     color: #000000;
//   }
// }
// .vms-container--login .mdc-checkbox {
//   height: inherit;
//   width: inherit;
//   padding: 1px;
// }
// .mdc-form-field {
//   font-size: inherit;
//   > label {
//     padding-left: 0px;
//   }
// }

// .vms-label--title {
//   font-size: 0.75rem;
//   font-weight: 600;
//   color: #000000;
// }

// .vms-container--login {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// }
// .vms-container--login .mdc-text-field__input {
//   height: 42px;
// }
// .vms-container--login .mdc-button {
//   height: 42px;
// }

// .vms-login-bg {
//   background: url("../login-vehicle-bg.jpg") no-repeat;
//   height: 100%;
//   background-color: #d8dede;
// }
// .vms-login-title--blue {
//   color: var(--mdc-theme-primary);
//   font-weight: bold;
//   font-size: 32px;
// }
// .vms-login-title--black {
//   color: #000;
//   font-weight: bold;
//   font-size: 32px;
// }

// .vms-container--login input[type="checkbox"] {
//   height: 16px;
//   width: 16px;
//   border-radius: 4px;
//   background: var(--mdc-theme-primary);
//   color: var(--mdc-theme-primary);
// }

.page-wrapper .login-bg {
  background: url("../../login-vehicle-bg.jpg") no-repeat;
  height: 100%;
  background-color: #d8dede;
}

.login-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  label {
    font-size: 0.75rem;
    font-weight: 600;
    color: #000000;
  }

  .mdc-text-field__input,
  .mdc-button {
    height: 42px !important;
  }
  .mdc-button {
    font-size: 14px !important;
  }
  .title--blue {
    color: var(--mdc-theme-primary);
    font-weight: bold;
    font-size: 2rem;
  }
  .title--black {
    color: #000;
    font-weight: bold;
    font-size: 2rem;
  }
  input[type="checkbox"] {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    background: var(--mdc-theme-primary);
    color: var(--mdc-theme-primary);
  }
  .mdc-checkbox {
    height: inherit;
    width: inherit;
    padding: 1px;
  }
  .mdc-form-field {
    font-size: inherit;
    font-family: inherit;
    > label {
      padding-left: 0px;
    }
  }
}

// input {
//   &::-webkit-input-placeholder, /* WebKit browsers */
//   &::-moz-placeholder,
//   &::-ms-input-placeholder,
//   &::placeholder {
//     /* Internet Explorer 10+ */
//     text-transform: none;
//   }
// }

::placeholder {
  text-transform: none; /* Modern browsers */
}

.mdc-data-table__content {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
// .mdc-data-table__table {

//   th {
//     text-align: left;
//           &:first-child {
//             padding-left: 0;
//           }
//           &:last-child {
//             padding-right: 0;
//           }
//   }
//   // td, th {
//   //   padding: 0 5px;
//   // }
// }
.mdc-data-table {
  max-width: 100%;
  overflow: auto;
  min-width: 359px;
}
div.mdc-data-table {
  margin-bottom: 0.25rem;
}
// .mdc-data-table__table {
//   table-layout: fixed;
// }

.mdc-data-table__table .material-icons {
  width: 18px;
  height: 18px;
}

.WordCounter {
  font-size: 11px;
  padding: 3px !important;
}

/*#region Details page*/

.section-header {
  background: $section-header-bg;
  height: 40px;
  padding: 0 0.5rem;
  margin-bottom: 4px;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    flex-direction: row;

    // font-size: 0.75rem;
    // align-items: center;
    > a {
      display: flex;
      flex-direction: row;
      font-size: 0.75rem;
      align-items: center;      
      font-weight: 600;
    }
    > a:not(.text-white) {
      color: $mdc-theme-primary !important;
    }
    :not(:first-child) {
      border-left: 1px solid $gray;
    }
    > * {
      padding: 0 0.5rem;
    }
  }
}
.section-border {
  border: 1px solid $gray;
  .mdc-button:last-child {
    margin-bottom: 0.25rem; //space for button
  }

  a {
    color: $mdc-theme-primary;
    font-weight: 600;
  }
}
.bg-dark {
  a {
    color: $mdc-theme-primary;
  }
}
// .section-content {
//   padding: 0 0.5rem !important;
//   > div {
//     padding: 0.375rem 0;
//   }
//   > div:not(.last-row) {
//     border-bottom: 1px solid $gray;
//   }
// }
// .section-content--title {
//   color: $orange;
//   font-size: 0.88rem;
//   font-weight: bold;
// }

.section-body {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;

  > :not(:last-child),
  > :not(:only-child) {
    > :not(.mdc-data-table) {
      margin-right: 1.5rem;
    }
  }

  .section-body--content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    // align-content: space-between;
    width: 340px;

    &.content-stretch {
      width: 680px !important;
    }

    > div {
      padding: 0.375rem 0;
      display: inline-flex;
      justify-content: space-between;

      > label,
      > span {
        font-weight: 600;
        padding-right: 4px;
      }
      > div {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        :not(:first-child) {
          margin-left: 2px;
        }
      }
    }

    > div:not(:last-child) {
      border-bottom: 1px solid $gray;
    }

    .content-title {
      color: $orange;
      font-size: 0.88rem;
      font-weight: bold;
    }

    label.content-title {
      padding: 0.375rem 0;
    }
    div.content-title {
      border-bottom: 0px !important;
    }
  }

  .section-body--content-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    // align-content: space-between;
    border-bottom: 1px solid $gray;
    > div {
      padding: 0.375rem 0;
      display: inline-flex;
      justify-content: flex-start;
      width: 10.25rem;
      > label,
      > span {
        width: 120px;
        font-weight: 600;
      }
    }
    //TODO: check vendor details page
    // > div:not(:last-child) {
    //   border-bottom: 1px solid $gray;
    // }

    &.content-cols-2 {
      width: 42rem;
    }
  }
  .section-body--content-one-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-content: space-between;

    > div {
      padding: 0.375rem 0;
      display: inline-flex;
      justify-content: flex-start;
      > div:not(:last-child) {
        border-bottom: 1px solid $gray;
      }
      > label,
      > span {
        width: 120px;
        font-weight: 600;
      }
    }
  }
  .section-body--content-one-col-lg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-content: space-between;

    > div {
      padding: 0.375rem 0;
      display: inline-flex;
      justify-content: flex-start;
      > div:not(:last-child) {
        border-bottom: 1px solid $gray;
      }
      > label,
      > span {
        width: 300px;
        font-weight: 600;
      }
    }
  }
  .section-body--content-two-col {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    // align-content: space-between;
    width: 799px;
    border-bottom: 0px; //we are using .border-bottom for some cases
    > div {
      padding: 0.375rem 0;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      &:not(.w-100) {
        @media (max-width: 767px) {
          //small screens
          width: 100%;
        }
        @media (min-width: 768px) {
          width: calc(50% - 20px);
          margin-right: 16px;
        }
      }
      &.w-100 {
        width: 100% !important;
        textarea {
          width: calc(100% - 80px - 40px) !important;
          margin-left: 16px;
        }
        input[type="text"] {
          width: 30px !important;
          margin-bottom: auto;
        }
      }
      // margin-right: 16px;

      > label,
      > span {
        width: 120px;
        font-weight: 600;
      }
      input[type="text"],
      select {
        width: calc(380px - 120px - 16px - 16px) !important; //w-label-el-margin
        margin-left: 16px;
      }
    }
    &.space-between > div {
      justify-content: space-between !important;
    }
    // > div:not(:last-child) {
    //   border-bottom: 1px solid $gray;
    // }
    &.border-bottom {
      > div:not(.border-none) {
        border-bottom: 1px solid $gray;
      }
    }
  }
}

.section-footer {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $white-antique;
  font-size: 0.69rem;
  margin-top: 0.25rem;
  > div {
    padding: 0.375rem 0;
  }
}

.form-edit {
  .section-body--content > div {
    justify-content: flex-start !important;
    border-bottom: 0px !important;
    > label,
    > span {
      font-weight: 400 !important;
      padding-right: 4px !important;
      width: 80px !important;
    }
    input[type="text"],
    select {
      width: calc(340px - 100px - 16px - 16px) !important; //w-label-el-margin
    }
    input[type="text"].WordCounter {
      width: 30px !important;
    }
  }
}

.side-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  > div {
    margin-top: 1rem;
  }
  > div:first-child {
    margin-top: 1px;
  }
  .card-header {
    background: $dark;
    border: 1px solid $dark;
    height: 36px;
    color: $white;
    font-weight: 600;
    padding: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .card-content {
    padding: 6px;
    background: #edf1f5;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
.details-page {
  .tabs {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: start;
    font-weight: 600;
    border-bottom: 1px solid $gray;
    > div {
      // width: 120px;
      padding: 0.875rem 1.5rem;
      color: $gray-dark !important;
      a:active,
      a:focus,
      a:visited {
        color: $gray-dark !important;
      }
    }
    > div.active {
      border-bottom: 3px solid $mdc-theme-primary;
      color: $mdc-theme-primary !important;
      a:active,
      a:focus,
      a:visited {
        color: $mdc-theme-primary !important;
      }
    }
    .tab-item {
      color: $gray-dark;
    }
  }
}

.mdc-list-item .mdc-button {
  justify-content: start;
  padding: 0 5px;
}
.form-field--col-1 {
  min-width: 240px;
  margin-right: 0.5rem;
  > label {
    margin: 0 3px;
  }
}
/*#endregion Details page*/
.gridview-paging {
  a:hover {
    background-color: #303338;
    color: #fff;
  }
  td {
    padding-left: 5px !important;
    background-color: #303338;
  }
  span {
    color: #fff;
  }
}

.mdc-dialog {
  color: #000 !important;
  .mdc-dialog__content {
    color: #000 !important;
  }
}
/* === Import template variables === */
@import "./variables";

@import "../node_modules/@material/top-app-bar/mixins";
/* === SCSS Compass Functions === */
@import "../node_modules/compass-mixins/lib/compass";

/* === Template mixins === */
// @import "../shared/mixins/buttons";
@import "../shared/mixins/color";

/* === Core Styles === */
@import "../shared/functions";
@import "../shared/utilities";

/* === Core Styles === */
@import "../shared/site";


/* === Sample screens === */
// @import "../shared/sample-screens/error";
// @import "../shared/sample-screens/auth";

/* === Plugin Overides === */
// @import "../shared/components/plugin-overides/code-mirror";

@import './pages';
@import './navbar';
@import './sidebar';
@import './footer';
@import './layout';
@import '../vertical/vertical-wrapper';
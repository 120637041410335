/* Navbar */

.mdc-top-app-bar {
  @include mdc-top-app-bar-fill-color-accessible($top-app-bar-bg);
  @include mdc-top-app-bar-icon-ink-color(#000);
  padding: 7px 4px; //7px 20px
  z-index: 9;
  position: static;
  @media (max-width: 320px) {
    padding: 7px 10px;
  }
  .mdc-top-app-bar__section {
    .mdc-top-app-bar__title {
      font-weight: 400;
      margin-right: 36px;
    }
    .mdc-button {
      background-color: $dark;
    }
    
    input[type=text] {
      text-transform: uppercase;     
    }
    
    // .mdc-text-field--with-leading-icon {
    //   &.search-text-field {
    //     @include mdc-text-field-outline-shape-radius(40px, $mdc-text-field-density-scale, true);
    //     height: 37px;
    //     width: 317px;
    //     border-radius: 40px;
    //     box-shadow: 0 0 10px 5px rgba(211, 211, 211, 0.5);
    //     @media(max-width: 991px) {
    //       width: 180px;
    //     }
    //     .mdc-text-field__icon {
    //       top: 10px;
    //       font-size: 20px;
    //       color: #a9a7a7;
    //     }
    //     .mdc-text-field__input {
    //       &:hover {
    //         ~ .mdc-notched-outline {
    //           .mdc-notched-outline__leading,
    //           .mdc-notched-outline__notch,
    //           .mdc-notched-outline__trailing {
    //             border-color: transparent;
    //           }
    //         }
    //       }
    //     }
    //     .mdc-notched-outline {
    //       .mdc-notched-outline__leading,
    //       .mdc-notched-outline__notch,
    //       .mdc-notched-outline__trailing {
    //         border-color: transparent;
    //         background: #fff;
    //       }
    //       .mdc-notched-outline__notch {
    //         .mdc-floating-label {
    //           top: 10px;
    //           left: 3px;
    //           font-size: 12px;
    //           color: #a9a7a7;
    //           &.mdc-floating-label--float-above {
    //             opacity: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .mdc-top-app-bar__section-right {
    button {
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);
      &.material-icons {
        font-size: 20px;
      }
      i {
        font-size: 20px;
      }
      &:hover,
      &:focus,
      &:active {
        &::before {
          opacity: 0;
        }
      }
      &::after {
        background-color: transparent;
      }
    }
    button,
    .menu-button-container {
      button {
        width: 35px;
        min-width: 35px;
        color: #000;
        .count-indicator {
          width: 13px;
          height: 13px;
          border-radius: 13px;
          @include display-flex;
          @include align-items(center);
          @include justify-content(center);
          position: absolute;
          top: 5px;
          left: 18px;
          background: theme-color(danger);
          .count {
            font-size: 7px;
            color: #fff;
          }
        }
      }
    }
    .menu-button-container {
      .mdc-menu {
        min-width: 190px;
        .title {
          padding: 18px 36px;
          margin-bottom: 0;
          border-bottom: 1px solid $border-color;
          font-weight: normal;
          + .mdc-list {
            padding-top: 0;
          }
        }
        .mdc-list {
          .mdc-list-item {
            white-space: nowrap;
            padding: 10px $mdc-list-side-padding;
            height: auto;
            border-bottom: 1px solid $border-color;
            &:last-child {
              border-bottom: none;
            }
            &:nth-child(1n + 0) {
              .item-thumbnail-icon {
                background-color: rgba(theme-color(primary), 0.1);
                i {
                  color: theme-color(primary);
                }
              }
            }
            &:nth-child(2n + 0) {
              .item-thumbnail-icon {
                background-color: rgba(theme-color(secondary), 0.1);
                i {
                  color: theme-color(secondary);
                }
              }
            }
            &:nth-child(3n + 0) {
              .item-thumbnail-icon {
                background-color: rgba(theme-color(info), 0.1);
                i {
                  color: theme-color(info);
                }
              }
            }
            &:nth-child(4n + 0) {
              .item-thumbnail-icon {
                background-color: rgba(theme-color(warning), 0.1);
                i {
                  color: theme-color(warning);
                }
              }
            }
            &:nth-child(5n + 0) {
              .item-thumbnail-icon {
                background-color: rgba(theme-color(dark), 0.1);
                i {
                  color: theme-color(dark);
                }
              }
            }
            .item-content {
              .item-subject {
                margin-bottom: 0;
              }
            }
            .item-thumbnail {
              width: 36px;
              height: 36px;
              margin-right: 15px;
              img {
                height: 36px;
                border-radius: 36px;
                display: block;
              }
              &.item-thumbnail-icon {
                height: 40px;
                width: 40px;
                border-radius: 36px;
                @include display-flex;
                @include justify-content(center);
                @include align-items(center);
                i {
                  font-size: 18px;
                  display: block;
                }
              }
              &.item-thumbnail-icon-only {
                width: auto;
                height: auto;
                font-size: 19px;
              }
            }
          }
        }
      }
    }
    .divider {
      width: 1px;
      height: 25px;
      background: #d6d7da;
      margin-right: 15px;
      margin-left: 15px;
      @media (max-width: 991px) {
        margin-right: 0;
        margin-left: 0;
      }
    }
    .menu-profile {
      button {
        width: auto;
        letter-spacing: normal;
        .figure {
          overflow: hidden;
          margin: 0;
          height: 25px;
          img {
            height: 100%;
            border-radius: 25px;
            margin-right: 10px;
          }
        }
        .user-name {
          font-size: 14px;
          color: #000;
          font-weight: normal;
        }
      }
    }
  }
}

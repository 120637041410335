/* Sidebar */

.mdc-drawer {
  // background: linear-gradient(#2a1c5a, #4b3a6e);
  // background: url(../../images/sidebar/sidebar-bg.jpg);
  background: #303338;
  font-weight: 600;
  position: fixed;
  &--open,
  &__drawer {
    width: $mdc-drawer-width;
  }
  .mdc-drawer-menu > .mdc-drawer-item {
    > .mdc-drawer-link, > .mdc-expansion-panel-link {
      padding-left: 4px !important;
  }
    
  }
  .mdc-drawer__header {
    padding: 0;
    margin: $mdc-drawer-list-item-margin;
    margin-top: 0;
    margin-bottom: 0;
    .brand-logo {
      display: block;
      padding: 28px 0;
    }
  }
  .mdc-drawer__content {
    .user-info {
      color: $mdc-drawer-link-color;
      margin: $mdc-drawer-list-item-margin;
      margin-bottom: 22px;
      font-weight: 300;
      .name {
        font-weight: 500;
      }
      p {
        margin-bottom: 6px;
      }
    }    
    .mdc-drawer-menu {
      .mdc-drawer-item {
        cursor: pointer;
        display: block;
        height: auto;
        padding: 0;
        font-weight: 600;
        border-bottom: 1px solid $mdc-drawer-item-border-color;
        margin: $mdc-drawer-list-item-margin;
        @include border-radius(2px);

        &:before {
          z-index: -1;
        }

        &:active {
          &:before {
            opacity: 0;
          }
        }
        .mdc-expansion-panel-link,
        .mdc-drawer-link {
          height: 32px; //40px;
          line-height: 0.85rem;//48px;
          // padding: 0 0.75rem;
          @include display-flex();
          @include align-items(center);
          // font-size: 0.875rem;
          font-weight: 600;
          text-decoration: none;
          color: $mdc-drawer-link-color;

          i {
            color: inherit;
            &.mdc-drawer-item-icon {
              font-size: 1.25rem;
              margin-right: 0.562rem;
            }
          }

          .mdc-drawer-arrow {
            margin-left: auto;
            font-size: 1.25rem;
            transition: all .3s ease;
            &.expanded {
              transform: rotate(90deg);
            }
          }

          &.expanded {
            .mdc-drawer-arrow {
              transform: rotate(90deg);
            }
          }

          &.active {
            background: $mdc-drawer-item-active;// #fff;
            color: #000;
            // font-weight: bold;
          }
        }

        .mdc-expansion-panel {
          display: none;
        }
      }
      
        .mdc-drawer-submenu {
          .mdc-drawer-item{
            border-bottom: 0px;
            margin-left: 22px;
          }
        }        
    }
    .profile-actions {
      @include display-flex;
      margin: $mdc-drawer-list-item-margin;
      margin-top: 20px;
      margin-bottom: 38px;
      padding: 0 0.75rem;
      a {
        font-weight: 500;
        font-size: 12px;
        color: #bababa;
        padding: 0 10px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      .divider {
        width: 1px;
        height: 5px;//10px;
        margin-top: 1px;
        background: #bababa;
      }
    }
    .mdc-expansion-panel {
      background-color: $mdc-drawer-item-expand-bg-color;
      margin-left: 0px; //12px
      margin-right: 0px; //12px
      margin-bottom: 4px;

      div .text-primary {
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        font-size: 0.6875rem;
      }
    }   
    .mdc-expansion-panel .mdc-drawer-item .mdc-drawer-link {      
      padding-left: 12px;
    } 
    .mdc-expansion-panel .mdc-drawer-item div {      
      padding-left: 12px;
    }
    // .premium-card {
    //   margin: $mdc-drawer-list-item-margin;
    //   margin-right: 27px;
    //   margin-bottom: 50px;
    //   background: #5458e1;
    //   color: #fff;
    //   padding: 25px;
    //   p {
    //     line-height: 1.3;
    //   }
    //   .icon-card {
    //     width: 32px;
    //     height: 32px;
    //     padding: 0;
    //     box-shadow: none;
    //     @include display-flex;
    //     @include align-items(center);
    //     @include justify-content(center);
    //     background: rgba(255, 255, 255, .2);
    //     i {
    //       font-size: 20px;
    //     }
    //   }
    //   .mdc-button {
    //     height: 26px;//40px;
    //     font-size: 12px;
    //     font-weight: bold;
    //   }
    // }
  }

  .change-system {
    margin-top: 0;
    margin-bottom: 0px;
    padding: $mdc-drawer-list-item-margin;
    background-color: #4D5056;
    padding-top: 5px;
    padding-bottom: 0px;
    select, .mdc-select__selected-text {
      background-color: #4D5056;
      color: #fff;
      border: 0px;
      padding-left: 0px;
      
    }
    .mdc-select__anchor{
      border: 0px;
      height: 28px;
      width: 100%;
      border-radius: 0px;
      padding-left: 0px;
      background-color: #4D5056 !important;
    }
    .mdc-select__dropdown-icon{
      background-color: #4D5056;
      color: #fff;
      margin: 0px;
      padding: 0px;
    }
    .mdc-select {
      background-color: #4D5056 !important;
      height: 32px;
    }
    .select__dropdown-icon{
      filter: grayscale(1);
    }
  }

  .logout {
    height: 51px; //40px;    
    margin-top: 0;
    margin-bottom: 0;
    padding: $mdc-drawer-list-item-margin;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #44474C;
    color: #E9ECF0;
    i {
        color: inherit;
        &.mdc-drawer-item-icon {
          font-size: 1.25rem;
          // margin-right: 0.562rem;
        }
      }
    a {      
      font-weight: 600;      
      display: flex;
      color:$white;
    }
  }
  .last-login {
    font-size: 10px;
    color: #9AA1AC;
  }

  .mdc-drawer .mdc-drawer__content .mdc-drawer-menu .mdc-drawer-item .mdc-expansion-panel-link {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .ps {
    .ps__rail-y {
      &:hover {
        background: transparent;
        .ps__thumb-y {
          width: 6px;
        }
      }
      .ps__thumb-y {
        background-color: #6d6386;
        width: 4px;
      }
    }
  }
}

#more-menu {  
  width: 234px; 
  margin-bottom: 15px;
  li:not(:last-child) {
    border-bottom: 1px solid $gray;    
  }
  li {
    padding: 2px;
  }
}
.avatar {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  /* Secondary/$malibu */
  background: #59B4E7;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  display: flex;
  align-items: center;  
  justify-content: center;
  /* Grays/$white */
  color: #FFFFFF;
}
.connect-ok {
  color: #5ABE8E;
}
.connect-init {
  /* Grays/$gray */
  color: #777C84;
}